

ready(function() {



  var hasParent = function(el, className) {
    
    if (el) {
      do {
        if (el.classList && el.classList.contains(className)) {
          return true;
        }
        if (el.nodeType === 9) {
          break;
        }
      }
      while((el = el.parentNode));
    }
    return false;
  };

  var toggleTabindex = function(offCanvasLinks) {

    for ( let i = 0; i < offCanvasLinks.length; i++ ) {
      
      window.requestAnimationFrame( () => {
        if (document.getElementsByTagName('html')[0].classList.contains('js-nav')) {
          offCanvasLinks[i].removeAttribute('tabindex');
        } else {
          offCanvasLinks[i].setAttribute('tabindex', '-1');
        }
      });

    }

  }

  // Tabsteuerung bei geschlossenem Offcanvas deaktivieren
  let offCanvasLinks = document.querySelectorAll('.a5-offcanvas-sidebar a, .a5-offcanvas-sidebar input, .a5-offcanvas-sidebar button');
  let hamburger = document.getElementById('hamburger');

  toggleTabindex(offCanvasLinks);

  hamburger.addEventListener('click', (event) => {
    toggleTabindex(offCanvasLinks);
  });

  if ( window.innerWidth < 980 ) {
    document.querySelector('.js-close').addEventListener('click', (event) => {
      toggleTabindex(offCanvasLinks);
    });
  }

  // close nav by touching the partial off-screen content
  document.addEventListener('click', function(e) {
    if (e.target.id == 'hamburger' || hasParent(e.target, 'hamburger')) {
      return;
    }
    if (document.getElementsByTagName('html')[0].classList.contains('js-nav') && !hasParent(e.target, 'a5-offcanvas-sidebar')) {
      document.getElementsByTagName('html')[0].classList.toggle('js-nav');
      hamburger.setAttribute('aria-expanded', false);
      hamburger.setAttribute('aria-label', hamburger.getAttribute('data-label-open'));
      toggleTabindex(offCanvasLinks);
    }
  },
  true);

});