/*
 *
 * Prototype Barrierekompass
 *
 * @date   2022-10
 *
 */
 


// ------------------------------------------
// no-js - falls modernizr deaktiviert wird
// document.documentElement.className = document.documentElement.className.replace('no-js','js');
 


// ------------------------------------------
// sticky hover fix für iOS 
(function(l){var i,s={touchend:function(){}};for(i in s)l.addEventListener(i,s)})(document);



// ------------------------------------------
// Helper
const createElement = domString => new DOMParser().parseFromString(domString, 'text/html').body.firstChild;



// ------------------------------------------
// Dom ready
// use: ready(function() { alert('hello'); });
window.ready = function(fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}



// ------------------------------------------
// High-Contrast Mode
function HCTest() {
  var objDiv = document.createElement('div');
  objDiv.style.color = 'rgba(245,240,255,0)';
  document.body.appendChild(objDiv);
  var strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;
  strColor = strColor.replace(/ /g, '');
  document.body.removeChild(objDiv);
  if (strColor !== 'rgba(245,240,255,0)') { document.body.classList.add('hcm-true'); }
}
ready(function() { HCTest(); });



// ------------------------------------------
// Lazyloading ( npm install lazysizes --save )
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

lazySizes.cfg.nativeLoading = {
  setLoadingAttribute: true,
  disableListeners: {
    scroll: true
  },
};

lazySizes.cfg.lazyClass = 'js-load';
lazySizes.cfg.loadingClass = 'js-loading';
lazySizes.cfg.loadedClass = 'js-loaded';
lazySizes.cfg.autosizesClass = 'js-autosizes';



// --------------------------------------
// Printlink
if (document.querySelector('.a5-nav-meta')) {

  let metaPrintlink = document.createElement('a');
  let metaPrintlinkWrap = document.createElement('li');
  
  metaPrintlink.innerHTML = printlink;
  metaPrintlink.href = 'javascript:window.print();';
  metaPrintlinkWrap.appendChild(metaPrintlink);
  document.querySelector('.a5-nav-meta ul').appendChild(metaPrintlinkWrap);

}



// --------------------------------------
// Tabellen

let tables = document.querySelectorAll('.a5-table');

function tableCheckWidth (element) {
  if (element.querySelector('table').offsetWidth > element.querySelector('div.a5-table-inner').offsetWidth) {
    element.classList.add('has-scroll');
  } else {
    element.classList.remove('has-scroll');
  }
}

for(let i = 0; i < tables.length; i++) {
  tables[i].innerHTML = '<div class="a5-table-inner">' + tables[i].innerHTML + '</div>';
  tableCheckWidth(tables[i]);
}

window.addEventListener('resize', (event) => {
  for(let i = 0; i < tables.length; i++) {
    tableCheckWidth(tables[i]);
  }
}, false);

window.addEventListener('orientationchange', (event) => {
  for(let i = 0; i < tables.length; i++) {
    tableCheckWidth(tables[i]);
  }
}, false);



// --------------------------------------
// Toplink
require('./partials/toplink.js');



// ------------------------------------------
// Lightbox
import baguetteBox from './moduls/baguettebox.js';

ready(function() {

  var lightbox = baguetteBox.run('.tm-images',{
    animation: 'fadeIn',
    filter: /.+\.(gif|jpe?g|png|webp)/i,
    overlayBackgroundColor: ''
  });

  if (lightbox.length > 0) {

    document.getElementById('close-button').setAttribute('aria-label', window.lightbox.close);
    document.getElementById('previous-button').setAttribute('aria-label', window.lightbox.prev);
    document.getElementById('next-button').setAttribute('aria-label', window.lightbox.next);

    document.getElementById('previous-button').addEventListener('keydown', function (event){
      if(event.shiftKey && event.keyCode == 9) { 
        event.preventDefault();
        document.getElementById('close-button').focus();
      }
    });

  }

});



// ------------------------------------------
// Mobilnavigation mit Slideouts

import pxNavigationToggle from './moduls/pxNavigationToggle.js';

ready(function() {

  let mobilnavigation = new pxNavigationToggle('.a5-nav-mobil',{
    'prefix' : 'a5',
    'selector' : '.a5-nav-mobil > ul > li > ul'
  });

  let navMobil = document.querySelector('.a5-nav-mobil');
  let buttonLabel;
  let expandButtons = navMobil.querySelectorAll('li button');
  let expandedButtons = navMobil.querySelectorAll('li.open > button');
  let i = 0;

  // Button: aria-label korrigieren
  for(i = 0; i < expandButtons.length; i++) {

    buttonLabel = document.getElementById(expandButtons[i].getAttribute('aria-controls') + 'link').innerHTML + ': ';
    buttonLabel = buttonLabel + expandButtons[i].getAttribute('aria-label');
    expandButtons[i].setAttribute('aria-label', buttonLabel);

    expandButtons[i].addEventListener('click', (event) => {
      buttonLabel = document.getElementById(event.target.getAttribute('aria-controls') + 'link').innerHTML + ': ';
      buttonLabel = buttonLabel + event.target.getAttribute('aria-label');
      event.target.setAttribute('aria-label', buttonLabel);
    });

  }

  // aktiven Listenpunkt öffnen
  for(i = 0; i < expandedButtons.length; i++) {
    expandedButtons[i].click();
  }


});



// ------------------------------------------
// Hamburger und Mobilnavigation

import pxEasyExpand from './moduls/pxEasyExpand.js';

ready(function() {

  // Hamburger
  const hamburger = new pxEasyExpand('#hamburger',{
    'closeWithEsc' : true,
    'setRootClass' : 'js-nav',
    'setFocusToElement' : '.js-close',
    'keepFocusInTargetElement' : true,
    'addCloseButton' : true,
  });

  // Search
  const search = new pxEasyExpand('#search',{
    'closeWithEsc' : true,
    'setRootClass' : 'js-search',
    'setFocusToElement' : '.js-close',
    'keepFocusInTargetElement' : true,
    'addCloseButton' : true,
  });

  // Contact Element
  const contactBox = new pxEasyExpand('#button-contactbox',{
    'closeWithEsc' : true,
    'setRootClass' : 'contactbox-open',
    'keepFocusInTargetElement' : true,
  });

  // Sticky Footer Element
  if (document.querySelector('.mobil-sticky-footer')) {

    const stickyFooter = new pxEasyExpand('#msf-control',{
      'closeWithEsc' : true,
      'setRootClass' : 'msf-open',
      'keepFocusInTargetElement' : true,
    });
    document.getElementById('msf-control').click();

    let msfButtons = document.querySelectorAll('.msf-content button');

    for(let i = 0; i < msfButtons.length; i++) {

      new pxEasyExpand('#' + msfButtons[i].getAttribute('id'));

      msfButtons[i].addEventListener('click', function(){
        let msfBoxOpen = document.querySelectorAll('.msf-content button[aria-expanded="true"]');
        for(let i = 0; i < msfBoxOpen.length; i++) {
          if ( msfBoxOpen[i] != this ) {
            msfBoxOpen[i].click();
            this.focus();
          }
        }
      });

    }

  }

  // Toogle-Box und Accordeon
  // Toggle-Box
  let toggleBoxWrapper = document.querySelectorAll('.a5-toggle-box-wrapper');
  for(let i = 0; i < toggleBoxWrapper.length; i++) {

    let toogleMulti = true;

    if ( toggleBoxWrapper[i].getAttribute('data-multi') == 'false' ) {
      toogleMulti = false;
    }

    let toggleBox = toggleBoxWrapper[i].querySelectorAll('.a5-toggle-box');
    for(let j = 0; j < toggleBox.length; j++) {

      let toggleBoxButtonWrapper = toggleBox[j].querySelector('.a5-toggle-box-button');
      let toggleBoxButton = document.createElement('button');
      let toggleBoxContent = toggleBox[j].querySelector('.a5-toggle-box-content')

      toggleBoxButton.innerHTML = toggleBoxButtonWrapper.innerHTML;
      toggleBoxButtonWrapper.innerHTML = '';
      toggleBoxButtonWrapper.appendChild(toggleBoxButton);

      toggleBoxButton.setAttribute('type', 'button');
      toggleBoxButton.setAttribute('data-controls', 'a5-toggle-content-' + i + '-' + j);
      toggleBoxButton.id = 'a5-toggle-button-' + i + '-' + j;

      toggleBoxContent.id = 'a5-toggle-content-' + i + '-' + j;

      var tb = new pxEasyExpand('#a5-toggle-button-' + i + '-' + j,{
        'closeWithEsc' : false,
        'switchButtonHtml' : false,
        'setFocusToFirstElement' : false,
        'keepFocusInTargetElement' : false
      });

      let closeOthers = function (container,current) {
        let toggleBoxOpen = container.querySelectorAll('.a5-toggle-box-button button[aria-expanded="true"]');
        for(let i = 0; i < toggleBoxOpen.length; i++) {
          if ( toggleBoxOpen[i] != current ) {
            toggleBoxOpen[i].click();
            current.focus();
          }
        }
        
      }

      if ( toogleMulti === false ) {
        toggleBoxButton.addEventListener('click', function(){
          closeOthers(toggleBoxWrapper[i],this);
        });
      }

    }

  }

});



// ------------------------------------------
// Tabs

import Tabby from './moduls/tabby.polyfills.js';

ready(function() {

  // Tabby starten
  let tabContainer = document.querySelectorAll('[data-tabs]')
  for (let i = 0; i < tabContainer.length; i++) {
    tabContainer[i].setAttribute('data-tabs-'+i, '');
    new Tabby('[data-tabs-'+i+']');
  }


  // Fallback Accordion-Funktion für Mobilansicht
  let tabPanels = document.querySelectorAll('.tabs[data-tabs-accordion] .tabs-content [data-accordion-button]');
  for(let tp = 0; tp < tabPanels.length; tp++) {

    let tabPanel = tabPanels[tp];
    let tabPanelTabID = tabPanel.getAttribute('aria-labelledby');
    let tabPanelTab = document.getElementById(tabPanelTabID);

    // Button Erzeugen, Einfügen, Klick-Event
    let tabPanelButton = createElement('<button type="button" class="tabpanel-mobil-button" aria-controls="' + tabPanel.id + '" aria-label="' + tabPanel.getAttribute('data-accordion-button') + '">' + tabPanelTab.innerHTML + '</button>');
    if ( !tabPanel.hasAttribute('hidden') ) { tabPanelButton.setAttribute('aria-pressed','true') }
    tabPanel.parentNode.insertBefore(tabPanelButton, tabPanel);
    tabPanelButton.addEventListener('click', (event) => ((tabPanelTab) => {
      event.preventDefault();
      if ( tabPanelTab.getAttribute('aria-selected') == 'false' ) {
        tabPanelTab.click();
      } else {
        tabPanelButton.setAttribute('aria-pressed','false');
        tabPanelTab.setAttribute('aria-selected', false);
        tabPanel.setAttribute('hidden', true);
      }
    })(tabPanelTab));

    // Zustand synchron halten
    tabPanelTab.addEventListener('click', (event) => ((tabPanelTab,tabPanelButton) => {
      let tabPanelButtonCurrent = tabPanelButton.parentNode.querySelectorAll('button.tabpanel-mobil-button[aria-pressed="true"]');
      for(let c = 0; c < tabPanelButtonCurrent.length; c++) {
        tabPanelButtonCurrent[c].setAttribute('aria-pressed','false');
      }
      if ( tabPanelTab.getAttribute('aria-selected') == 'true' ) {
        tabPanelButton.setAttribute('aria-pressed','true');
        tabPanelButton.focus();
      }
    })(tabPanelTab,tabPanelButton));

    if (window.getComputedStyle(tabPanelButton).display !== 'none') {
      if ( tabPanelButton.getAttribute('aria-pressed') == 'true' ) {
        if (!tabPanelTab.hasAttribute('data-tabby-mobil-default')) {
          tabPanelButton.click();
        }
      }
    }

  }


  // Schließbare Tabs

  let tabContainerCloseable = document.querySelectorAll('[data-tabs-closeable]');

  if (tabContainerCloseable.length > 0) {

    // Tabby-Event
    document.addEventListener('tabby', function (event) {
      if ( event.target.parentNode.parentNode.hasAttribute('data-tabs-closeable') ) {
        let focusableTabs = event.target.parentNode.parentNode.querySelectorAll('a[tabindex="0"]');
        if (focusableTabs.length > 1) {
          focusableTabs[0].setAttribute('tabindex','-1');
        }
      }
    });

    for(let i = 0; i < tabContainerCloseable.length; i++) {

      let closeableTabs = tabContainerCloseable[i].querySelectorAll('a');

      let selectedTab;

      if (closeableTabs.length > 0) {
        closeableTabs[0].setAttribute('tabindex','0');
      }

      for(let j = 0; j < closeableTabs.length; j++) {

        closeableTabs[j].addEventListener('click', (event) => {

          let tab = closeableTabs[j];

          if (selectedTab == tab) {
            tab.setAttribute('aria-selected', false);
            document.getElementById( tab.hash.replace('#', '') ).setAttribute('hidden','true');
            selectedTab = null;
          } else {
            tab.setAttribute('aria-selected', true);
            document.getElementById( tab.hash.replace('#', '') ).removeAttribute('hidden');
            selectedTab = tab;
          }

        });

        closeableTabs[j].addEventListener('focus', (event) => {

          if (event.target.getAttribute('aria-selected') === 'true') {
            selectedTab = closeableTabs[j];
          }

        });

      }

    }

  }


});



// ------------------------------------------
// Sticky
import Sticky from './moduls/sticky.js';

ready(function() {

  let stickyElements = document.querySelectorAll('.sticky');
  
  if ( stickyElements.length > 0 ) {

    for(let i = 0; i < stickyElements.length; i++) {
      // grid as sticky container
      stickyElements[i].parentElement.parentElement.setAttribute('data-sticky-container', true);
    }
    
    var sticky = new Sticky('.sticky');

    window.addEventListener('resize', (event) => {
      sticky.elements[0].parentNode.removeAttribute('style');
    }, false);

    window.addEventListener('orientationchange', (event) => {
      sticky.elements[0].parentNode.removeAttribute('style');
    }, false);

  }

  

});



// ------------------------------------------
// Slider
import Swiper from './moduls/swiper.js';

ready(function() {

  const sliderContainer = document.querySelectorAll('.a5-logos-slider .logos-slider-inner');
  let i = 0;

  for(i = 0; i < sliderContainer.length; i++) {

    let sliderUL = sliderContainer[i].querySelector('ul');
    let sliderItems = sliderUL.querySelectorAll('li');
    let j = 0;

    if ( sliderItems.length < 2 ) {
      continue;
    }

    sliderUL.classList.add('slider-wrapper');

    for (j = 0; j < sliderItems.length; j++) {
      sliderItems[j].classList.add('slider-item');
    }

    let buttonWrapper = document.createElement('div');
    buttonWrapper.setAttribute('class', 'slider-controls');
    //sliderContainer[i].insertBefore(buttonWrapper,sliderContainer[i].firstChild);
    sliderContainer[i].parentNode.append(buttonWrapper);

    let buttonNext = document.createElement('button');
    buttonNext.innerHTML = swiper.next;
    buttonNext.setAttribute('class', 'slider-next-' + i);
    buttonNext.setAttribute('type', 'button');
    // buttonNext.addEventListener('keydown', event => {
    //   event.preventDefault();
    // });
    buttonWrapper.insertBefore(buttonNext,buttonWrapper.firstChild);

    let buttonPrev = document.createElement('button');
    buttonPrev.innerHTML = swiper.prev;
    buttonPrev.setAttribute('class', 'slider-prev-' + i);
    buttonPrev.setAttribute('type', 'button');
    // buttonPrev.addEventListener('keydown', event => {
    //   event.preventDefault();
    // });
    buttonWrapper.insertBefore(buttonPrev,buttonWrapper.firstChild);

    let slider = new Swiper(sliderContainer[i],{
      effect: 'slide',
      loop: true,
      autoHeight: true,
      slideClass: 'slider-item',
      wrapperClass: 'slider-wrapper',
      a11y: {
        prevSlideMessage: swiper.prevTitle,
        nextSlideMessage: swiper.nextTitle,
        firstSlideMessage: swiper.firstTitle,
        lastSlideMessage:  swiper.lastTitle,
        paginationBulletMessage: swiper.bulletTitle
      },
      navigation: {
        nextEl: buttonNext,
        prevEl: buttonPrev
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      slidesPerView: 2,
      spaceBetween: 15,
      breakpoints: {
        480: {
          slidesPerView: 4,
        },
        760: {
          slidesPerView: 6,
        },
        1280: {
          slidesPerView: 8,
        },
      }
    });

    // Warnungen des Validator vermeiden
    buttonPrev.removeAttribute('role');
    buttonNext.removeAttribute('role');

  }

});



// ------------------------------------------
// Isotope Filter
let Isotope = require('./scripts/isotope-layout');
let imagesLoaded = require('./scripts/imagesloaded');

ready(function() {

  const filterButtons = document.querySelectorAll('.a5-isotope-filter button');
  const filterItems = document.querySelectorAll('.a5-nav-isotope li');

  if (filterItems.length > 0) {

    // Elemente ohne Bild müssen ihre Höhe anpassen
    let updateHeight = function () {

      let filterItemWithImage = document.querySelector('.a5-nav-isotope li:not(.no-image)');
      let filterItemsNoImage = document.querySelectorAll('.a5-nav-isotope li.no-image');

      for (let i = 0; i < filterItemsNoImage.length; i++) {
        filterItemsNoImage[i].style.height = filterItemWithImage.offsetHeight + 'px'; 
      }

    }

    window.addEventListener('resize', (event) => {
      updateHeight();
    }, false);

    window.addEventListener('orientationchange', (event) => {
      updateHeight();
    }, false);

    let a5Isotope = new Isotope('.a5-nav-isotope', {
      itemSelector: 'li',
      filter: 'li:not([aria-hidden])',
      layoutMode: 'fitRows'
    });

    imagesLoaded('.a5-nav-isotope').on( 'progress', function() {
      updateHeight();
      a5Isotope.arrange();
    });

    // zeige nur gefilterte Elemente an
    let updateFilter = function (button, filterItems) {
      
      let filterId = button.getAttribute('data-filter');

      for (let j = 0; j < filterItems.length; j++) {

        if (filterId == '*') {
          filterItems[j].removeAttribute('aria-hidden');
          continue;
        } else {
          filterItems[j].setAttribute('aria-hidden', true);
        }

        // ein Element kann mehreren Kategorien zugeordnet sein
        if (filterItems[j].hasAttribute('data-filter')) {
          if (filterItems[j].getAttribute('data-filter').split(',').indexOf(filterId) >= 0) {
            filterItems[j].removeAttribute('aria-hidden');
          }
        }

      }

      updateHeight();
      a5Isotope.arrange();

    }

    // Verhalten der Filter-Buttons steuern
    for (let i = 0; i < filterButtons.length; i++) {

      // ist ein Button initial aktiv, so werden die Elemente beim Laden der Seite gefiltert
      if (filterButtons[i].hasAttribute('aria-disabled')) {
        filterButtons[i].setAttribute('disabled', true);
        updateFilter(filterButtons[i], filterItems);
      }

      filterButtons[i].addEventListener('click', function(event) {

        event.preventDefault();

        for (let i = 0; i < filterButtons.length; i++) {

          // aktiviere alle inaktiven Buttons
          if (filterButtons[i].hasAttribute('aria-disabled')) {
            filterButtons[i].removeAttribute('aria-disabled');
            filterButtons[i].removeAttribute('disabled');
          }

        }

        // deaktiviere den geklickten Button
        this.setAttribute('aria-disabled', true);
        this.setAttribute('disabled', 'disabled');

        updateFilter(this, filterItems);

      });

    }

  }

});


// ------------------------------------------
// Offcanvas
require('./scripts/offcanvas.js');
if (document.getElementById('search')) {
  require('./scripts/offcanvas-search.js');
}


