"use strict";


class pxEasyExpand {


  constructor (buttonID, settings = {}) {

    this.defaults = {
        'setRootClass' : false, // soll eine Klasse im HTML gesetzt werden
        'switchButtonHtml' : false,
        'addCloseButton' : false,
        'closeWithEsc' : false,
        'setFocusToElement' : false, // Selector oder false
        'setFocusToFirstElement' : false,
        'keepFocusInTargetElement' : false,
        'disableFocusHandling' : false,
        'hiddenAttribute' : 'aria-hidden'
    };

    // Config erstellen
    this.config = Object.assign(this.defaults,settings);

    // ID des Button
    this.buttonObj = document.getElementById(buttonID.replace('#', ''));

    // der ein/auszublendender wird gespeichert Container
    this.targetObj = [];
    if ( this.buttonObj && this.buttonObj.hasAttribute('data-controls') ) {
      let targetIds = this.buttonObj.getAttribute('data-controls').split(' ')
      for(let i = 0; i < targetIds.length; i++) {
        this.targetObj[i] = document.getElementById( targetIds[i] );
      }
    } else if ( this.buttonObj && this.buttonObj.hasAttribute('href') ) {
      this.targetObj[0] = document.getElementById(this.buttonObj.hash.replace('#', ''));
    }

    // Abbruch falls es keinen Ziel-Container gibt
    if ( this.targetObj.length < 1 ) {
      return;
    }
    
    // Aria-Labels des Buttons
    this.labelOpen = this.buttonObj.hasAttribute('data-label-open') ? this.buttonObj.getAttribute('data-label-open') : false;
    this.labelClose = this.buttonObj.hasAttribute('data-label-close') ? this.buttonObj.getAttribute('data-label-close') : false;

    // Text des Buttons
    this.textOpen = this.buttonObj.hasAttribute('data-text-open') ? this.buttonObj.getAttribute('data-text-open') : this.labelOpen;
    this.textClose = this.buttonObj.hasAttribute('data-text-close') ? this.buttonObj.getAttribute('data-text-close') : this.labelClose;

    // Text des Focus Helpers
    this.focusHelperText = this.buttonObj.getAttribute('data-focus-helper-text');

    // auf gehts
    if (this.buttonObj) {

      // Aria-Attribute einfügen
      this.addAria();

      // Button belegen und Funktion anbinden
      this.buttonObj.addEventListener('click', (event) => {
        event.preventDefault();
        this.toggleStates();
      }, false);

      // Schließen-Button einfügen
      if (this.config.addCloseButton !== false) {
        this.closeButtonObj = document.createElement('button');
        this.closeButtonObj.innerHTML = '<span>' + this.textClose + '</span>';
        this.setAttributes(this.closeButtonObj, {
          'aria-controls': this.targetObj[0].id,
          'aria-expanded': 'true',
          'aria-label': this.labelClose,
          'class': 'js-close'
        });
        this.closeButtonObj.addEventListener('click', (event) => {
          event.preventDefault();
          this.toggleStates();
        }, false);
        this.targetObj[0].appendChild(this.closeButtonObj);
      }

      // Schließen mit ESC
      if (this.config.closeWithEsc === true) {
        this.targetObj[0].addEventListener('keyup', (event) => {
          if (event.defaultPrevented) { return; }
          var key = event.key || event.keyCode;
          if (key === 'Escape' || key === 'Esc' || key === 27) {
            event.preventDefault();
            this.toggleStates();
          }
        });
        this.buttonObj.addEventListener('keyup', (event) => {
          if (event.defaultPrevented || this.buttonObj.getAttribute('aria-expanded') === 'false') { return; }
          var key = event.key || event.keyCode;
          if (key === 'Escape' || key === 'Esc' || key === 27) {
            event.preventDefault();
            this.toggleStates();
          }
        });
      }

      // Tastatursteuerung / Tabreihenfolge: nach dem Steuerungsbutton direkt in das Fenster springen
      // soll nur bei TAB greifen -> (event.which || event.keyCode) == 9
      this.buttonObj.addEventListener('keydown', (event) => {
        if ( this.buttonObj.getAttribute('aria-expanded') === 'true' && (event.which || event.keyCode) == 9 && this.config.keepFocusInTargetElement === true) {
          event.preventDefault();

          // kleine Schwachstelle: theoretisch könnten auch andere Elemente Focus erhalten
          var focusableElements = this.targetObj[0].querySelectorAll('a[href]:not(.focus-helper),input,button');
          var visibleFocusableElements = [];

          for (var i = 0; i < focusableElements.length; i++) {
            if (!this.isHidden(focusableElements[i])) {
              visibleFocusableElements.push(focusableElements[i]);
            }
          }

          if ( event.shiftKey === false && visibleFocusableElements.length > 0 ) {
            visibleFocusableElements[0].focus();
          } else if ( event.shiftKey === true && visibleFocusableElements.length > 0 ) {
            visibleFocusableElements[visibleFocusableElements.length - 1].focus();
          }
        }
      });

      // Tastatursteuerung / Tabreihenfolge
      if (this.config.keepFocusInTargetElement === true) {

        // 1. beim Rückwärtsverlassen des ersten Elements, wird der Focus zurück
        // auf den Öffnen-Button geschoben  
        this.targetObj[0].addEventListener('keydown', (event) => {

          // Abbruch falls das Focus Handling deaktiviert wurde
          if ( this.config.disableFocusHandling === true ) { return; }

          // Abbruch, falls Buttons versteckt sind
          if ( this.config.addCloseButton === true ) {
            if ( this.isHidden(this.closeButtonObj) ) { return; }
          } else {
            if ( this.isHidden(this.buttonObj) ) { return; }
          }

          if ( event.shiftKey === true && (event.which || event.keyCode) == 9 ) {

            // kleine Schwachstelle: theoretisch könnten auch andere Elemente Focus erhalten
            if ( this.targetObj[0].querySelector('a[href],input,button') == event.target ) {
              event.preventDefault();

              if ( this.config.addCloseButton === true ) {
                this.closeButtonObj.focus();
              } else {
                this.buttonObj.focus();
              }

            }

          }

        });

        // 2. beim Erreichen eines hier eingefügten Hilfslinks am Ende des Containers,
        // wird ebenfalls der Focus zurück auf den Öffnen-Button geschoben
        var focusHelper = document.createElement('a');
          
        this.setAttributes(focusHelper, {
          'aria-label': this.focusHelperText,
          'href': '#' + this.buttonObj.id,
          'class': 'focus-helper'
        });

        focusHelper.innerHTML = '<span>' + this.focusHelperText + '</span>';

        focusHelper.addEventListener('focus', (event) => {

          event.preventDefault();

          if ( this.config.addCloseButton === true ) {

            // kleine Schwachstelle: theoretisch könnten auch andere Elemente Focus erhalten
            var element = this.targetObj[0].querySelector('a[href],input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]),button');
            if (element !== null) {
              element.focus();
            }

          } else {
            this.buttonObj.focus();
          }

        });

        this.targetObj[0].appendChild(focusHelper);

      }

    }

  }


  isHidden (element) {

    // position fixed 
    // var style = window.getComputedStyle(element);
    // return (style.display === 'none')

    return (element.offsetParent === null)
  
  }


  setAttributes (element, attributes) {
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
  }


  toggleStates () {

    var expanded = false;

    if ( this.buttonObj.getAttribute('aria-expanded') === 'true' ) {
      expanded = true;
    }

    window.requestAnimationFrame( () => {

      if (this.config.setRootClass !== false) {
        document.getElementsByTagName('html')[0].classList.toggle(this.config.setRootClass);
      }

      this.buttonObj.setAttribute('aria-expanded',!expanded);

      if (this.labelOpen && this.labelClose) {
        this.buttonObj.setAttribute('aria-label',expanded ? this.labelOpen : this.labelClose);
      }

      if (this.config.switchButtonHtml !== false) {
        this.buttonObj.innerHTML = expanded ? '<span>' + this.textOpen + '</span>' : '<span>' + this.textClose + '</span>';
      }

      for(let i = 0; i < this.targetObj.length; i++) {
        this.targetObj[i].setAttribute(this.config.hiddenAttribute, expanded);
      }

    });

    if (expanded) {

      this.buttonObj.focus();

    } else {
      
      if ( this.config.setFocusToElement !== false ) {
        var element = this.targetObj[0].querySelector(this.config.setFocusToElement);
        if ( element.length !== null ) {
          setTimeout( () => {
            element.focus();
          }, 100);
          return;
        }
      }

      if (this.config.setFocusToFirstElement === true) {
        setTimeout( () => {
          (this.targetObj[0].querySelector('a[href],input,button') || this.targetObj[0]).focus();
        }, 100);
      }
      
    }

  };


  disableFocusHandling(status) {
    this.config.disableFocusHandling = status;
  }


  addAria () {

    let controls = [];
    let hiddenAttribute = this.config.hiddenAttribute;

    for(let i = 0; i < this.targetObj.length; i++) {
      this.targetObj[i].setAttribute(hiddenAttribute,true);
      controls.push(this.targetObj[i].id);
    }

    if ( this.buttonObj.nodeName != 'BUTTON' ) {
      this.buttonObj.setAttribute('role','button');
    }
    
    this.setAttributes(this.buttonObj, {
      'aria-controls': controls.join(' '),
      'aria-expanded': 'false'
    });

    if (this.labelOpen) {
      this.buttonObj.setAttribute('aria-label',this.labelOpen);
    }

  };


}


export default pxEasyExpand;

