

ready(function() {



  var hasParent = function(el, className) {
    
    if (el) {
      do {
        if (el.classList && el.classList.contains(className)) {
          return true;
        }
        if (el.nodeType === 9) {
          break;
        }
      }
      while((el = el.parentNode));
    }
    return false;
  };

  var toggleTabindex = function(offCanvasLinks) {

    for ( let i = 0; i < offCanvasLinks.length; i++ ) {
      
      window.requestAnimationFrame( () => {
        if (document.getElementsByTagName('html')[0].classList.contains('js-search')) {
          offCanvasLinks[i].removeAttribute('tabindex');
        } else {
          offCanvasLinks[i].setAttribute('tabindex', '-1');
        }
      });

    }

  }

  // Tabsteuerung bei geschlossenem Offcanvas deaktivieren
  let offCanvasLinks = document.querySelectorAll('.a5-offcanvas-sidebar-search a, .a5-offcanvas-sidebar-search input, .a5-offcanvas-sidebar-search button');
  let search = document.getElementById('search');

  toggleTabindex(offCanvasLinks);

  search.addEventListener('click', (event) => {
    toggleTabindex(offCanvasLinks);
  });

  if ( window.innerWidth < 980 ) {
    document.querySelector('.js-close').addEventListener('click', (event) => {
      toggleTabindex(offCanvasLinks);
    });
  }

  // close nav by touching the partial off-screen content
  document.addEventListener('click', function(e) {
    if (e.target.id == 'search' || hasParent(e.target, 'search')) {
      return;
    }
    if (document.getElementsByTagName('html')[0].classList.contains('js-search') && !hasParent(e.target, 'a5-offcanvas-sidebar-search')) {
      document.getElementsByTagName('html')[0].classList.toggle('js-search');
      search.setAttribute('aria-expanded', false);
      search.setAttribute('aria-label', search.getAttribute('data-label-open'));
      toggleTabindex(offCanvasLinks);
    }
  },
  true);

});