"use strict";


class pxNavigationToogle {


  constructor (navigationSelector, settings = {}) {

    this.defaults = {
      'prefix' : 'px',
      'selector' : 'ul ul', // Beispiel: 'nav > ul > li > ul'
      'buttonReplaceLink' : false
    };

    // Config erstellen
    this.config = Object.assign(this.defaults,settings);

    // Navigation
    this.navigation = document.querySelector(navigationSelector);

    if ( !this.navigation ) { return }
    
    // Aria-Labels des Buttons
    this.labelOpen = this.navigation.hasAttribute('data-label-open') ? this.navigation.getAttribute('data-label-open') : false;;
    this.labelClose = this.navigation.hasAttribute('data-label-close') ? this.navigation.getAttribute('data-label-close') : false;

    // Text des Button
    this.textOpen = this.navigation.hasAttribute('data-text-open') ? this.navigation.getAttribute('data-text-open') : this.labelOpen;
    this.textClose = this.navigation.hasAttribute('data-text-close') ? this.navigation.getAttribute('data-text-close') : this.labelClose;

    // Listen suchen
    this.navigationSublists = this.navigation.parentNode.querySelectorAll(this.config.selector);
    let index = 0;
    
    // Button und Funktionen einfügen
    for( index=0; index < this.navigationSublists.length; index++ ) {

      var list = this.navigationSublists[index];
      var link = list.previousElementSibling;

      var linkID = this.config.prefix + index + 'link';
      var listID = this.config.prefix + index;
      var btnID = this.config.prefix + index + 'btn';

      var button = document.createElement('button');

      if ( this.config.buttonReplaceLink === true ) {
        button.innerHTML = link.innerHTML;
        link.remove();
      } else {
        button.innerHTML = this.textOpen;
        link.setAttribute('id',linkID);
        list.setAttribute('aria-describedby', linkID);
      }
      
      this.setAttributes(button, {
          'aria-controls': listID,
          'aria-expanded': 'false',
          'class': 'js-btn',
          'id': btnID,
      });

      if (this.labelOpen) {
        button.setAttribute('aria-label',this.labelOpen);
      }

      button.addEventListener('click', (event) => {
        this.toggleAriaStates(event);
      }, false);
      
      list.parentNode.insertBefore(button,list);

      this.setAttributes(list,{
        'aria-hidden': true,
        'id': listID,
      });
      
      
    }

  }


  setAttributes (element, attributes) {
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
  }


  toggleAriaStates (event) {

    event.preventDefault();

    var button = event.target;
    var list = document.getElementById( button.getAttribute('aria-controls') );

    var expanded = false;
    var buttonText = this.textClose;

    if ( button.getAttribute('aria-expanded') === 'true' ) {
      expanded = true;
      buttonText = this.textOpen;
    }

    list.setAttribute('aria-hidden',expanded);

    button.setAttribute('aria-expanded',!expanded);

    if (this.labelOpen) {
      button.setAttribute('aria-label', expanded ? this.labelOpen : this.labelClose );
    }

    if ( this.config.buttonReplaceLink !== true ) {
      button.innerHTML = buttonText;
    }

  };


}


export default pxNavigationToogle;

